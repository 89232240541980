(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/sport-types/assets/javascripts/sport-types.js') >= 0) return;  svs.modules.push('/components/sport/sport-types/assets/javascripts/sport-types.js');
"use strict";


const SportTypes = {
  Undefined: 0,
  Soccer: 1,
  Hockey: 2,
  Bandy: 3,
  Handball: 4,
  Floorball: 5,
  Tennis: 6,
  Golf: 7,
  Speedway: 8,
  Basketball: 10,
  SkiingAlpine: 11,
  Formula1: 14,
  Athletics: 15,
  SkiingCrossCountry: 17,
  WinterOlympic: 26,
  Biathlon: 27,
  Baseball: 39,
  MartialArt: 41,
  Trotting: 58,
  Specialties: 59,
  SummerOlympic: 63,
  AmericanFootball: 89,
  Curling: 9,
  SkiJumping: 12,
  AmerikanskFotboll: 13,
  Rugby: 16,
  Melodifestivalen: 18,
  Cykel: 19,
  Simning: 20,
  Bordtennis: 21,
  Beachvolleyboll: 22,
  Indycar: 23,
  Boxning: 24,
  Livespel: 25,
  Skridsko: 28,
  NordiskKomb: 29,
  Freestyle: 30,
  Snowboard: 31,
  Orientering: 32,
  Dans: 33,
  Rally: 34,
  Snooker: 35,
  Os2012: 36,
  Konstakning: 37,
  Rallycross: 38,
  Nascar: 40,
  Isracing: 42,
  InlineHockey: 43,
  AussieRules: 44,
  Badminton: 45,
  Strandfotboll: 46,
  Futsal: 47,
  Boboll: 48,
  Vattenpolo: 49,
  Dart: 50,
  Motorsport: 51,
  Vintersporter: 52,
  Landhockey: 53,
  Lacrosse: 54,
  Horseball: 55,
  OlympicsYouth: 56,
  Surfing: 57,
  Cricket: 60,
  Biljard: 61,
  GaelicSport: 62,
  Bowling: 64,
  Schack: 65,
  Netball: 66,
  Squash: 67,
  Rullskridskohockey: 68,
  Motorcykel: 69,
  DTM: 70,
  Bob: 71,
  SpeedSkating: 72,
  Rodel: 73,
  Softball: 74,
  Schwingen: 75,
  Greyhound: 76,
  Gatuhockey: 77,
  VM: 78,
  Rodd: 79,
  SnowboardAFU: 80,
  MotoGP: 81,
  Moto2: 82,
  SnowboardASP: 83,
  _125cc: 84,
  Padel: 85,
  Kanot: 86,
  Cykelbana: 87,
  Volleyboll: 88,
  Triathlon: 90,
  Segling: 91,
  Skicross: 92,
  UndefinedSport93: 93,
  UndefinedSport94: 94,
  UndefinedSport95: 95,
  UndefinedSport96: 96,
  Shorttrack: 97,
  Skeleton: 98,
  UndefinedSport99: 99,
  UndefinedSport100: 100,
  UndefinedSport101: 101,
  UndefinedSport102: 102
};
const SportTypeToString = {
  [SportTypes.Undefined]: 'Ospecificerad',
  [SportTypes.Soccer]: 'Fotboll',
  [SportTypes.Hockey]: 'Ishockey',
  [SportTypes.Bandy]: 'Bandy',
  [SportTypes.Handball]: 'Handboll',
  [SportTypes.Floorball]: 'Innebandy',
  [SportTypes.Tennis]: 'Tennis',
  [SportTypes.Golf]: 'Golf',
  [SportTypes.Speedway]: 'Speedway',
  [SportTypes.Basketball]: 'Basket',
  [SportTypes.SkiingAlpine]: 'Alpint',
  [SportTypes.Formula1]: 'Formel 1',
  [SportTypes.Athletics]: 'Friidrott',
  [SportTypes.SkiingCrossCountry]: 'Längdskidåkning',
  [SportTypes.WinterOlympic]: 'Vinter OS',
  [SportTypes.Biathlon]: 'Skidskytte',
  [SportTypes.Baseball]: 'Baseboll',
  [SportTypes.MartialArt]: 'Kampsport',
  [SportTypes.Trotting]: 'Trav',
  [SportTypes.Specialties]: 'Specialiteter',
  [SportTypes.SummerOlympic]: 'Sommar OS',
  [SportTypes.AmericanFootball]: 'Amerikansk fotboll (NFL)',
  [SportTypes.Curling]: 'Curling',
  [SportTypes.SkiJumping]: 'SkiJumping',
  [SportTypes.AmerikanskFotboll]: 'AmerikanskFotboll',
  [SportTypes.Rugby]: 'Rugby',
  [SportTypes.Melodifestivalen]: 'Melodifestivalen',
  [SportTypes.Cykel]: 'Cykel',
  [SportTypes.Simning]: 'Simning',
  [SportTypes.Bordtennis]: 'Bordtennis',
  [SportTypes.Beachvolleyboll]: 'Beachvolleyboll',
  [SportTypes.Indycar]: 'Indycar',
  [SportTypes.Boxning]: 'Boxning',
  [SportTypes.Livespel]: 'Livespel',
  [SportTypes.Skridsko]: 'Skridsko',
  [SportTypes.NordiskKomb]: 'NordiskKomb',
  [SportTypes.Freestyle]: 'Freestyle',
  [SportTypes.Snowboard]: 'Snowboard',
  [SportTypes.Orientering]: 'Orientering',
  [SportTypes.Dans]: 'Dans',
  [SportTypes.Rally]: 'Rally',
  [SportTypes.Snooker]: 'Snooker',
  [SportTypes.Os2012]: 'Os2012',
  [SportTypes.Konstakning]: 'Konstakning',
  [SportTypes.Rallycross]: 'Rallycross',
  [SportTypes.Nascar]: 'Nascar',
  [SportTypes.Isracing]: 'Isracing',
  [SportTypes.InlineHockey]: 'InlineHockey',
  [SportTypes.AussieRules]: 'AussieRules',
  [SportTypes.Badminton]: 'Badminton',
  [SportTypes.Strandfotboll]: 'Strandfotboll',
  [SportTypes.Futsal]: 'Futsal',
  [SportTypes.Boboll]: 'Boboll',
  [SportTypes.Vattenpolo]: 'Vattenpolo',
  [SportTypes.Dart]: 'Dart',
  [SportTypes.Motorsport]: 'Motorsport',
  [SportTypes.Vintersporter]: 'Vintersporter',
  [SportTypes.Landhockey]: 'Landhockey',
  [SportTypes.Lacrosse]: 'Lacrosse',
  [SportTypes.Horseball]: 'Horseball',
  [SportTypes.OlympicsYouth]: 'OlympicsYouth',
  [SportTypes.Surfing]: 'Surfing',
  [SportTypes.Cricket]: 'Cricket',
  [SportTypes.Biljard]: 'Biljard',
  [SportTypes.GaelicSport]: 'GaelicSport',
  [SportTypes.Bowling]: 'Bowling',
  [SportTypes.Schack]: 'Schack',
  [SportTypes.Netball]: 'Netball',
  [SportTypes.Squash]: 'Squash',
  [SportTypes.Rullskridskohockey]: 'Rullskridskohockey',
  [SportTypes.Motorcykel]: 'Motorcykel',
  [SportTypes.DTM]: 'DTM',
  [SportTypes.Bob]: 'Bob',
  [SportTypes.SpeedSkating]: 'Speed Skating',
  [SportTypes.Rodel]: 'Rodel',
  [SportTypes.Softball]: 'Softball',
  [SportTypes.Schwingen]: 'Schwingen',
  [SportTypes.Greyhound]: 'Greyhound',
  [SportTypes.Gatuhockey]: 'Gatuhockey',
  [SportTypes.VM]: 'VM',
  [SportTypes.Rodd]: 'Rodd',
  [SportTypes.SnowboardAFU]: 'Snowboard AFU',
  [SportTypes.MotoGP]: 'MotoGP',
  [SportTypes.Moto2]: 'Moto2',
  [SportTypes.SnowboardASP]: 'Snowboard ASP',
  [SportTypes._125cc]: '125cc',
  [SportTypes.Padel]: 'Padel',
  [SportTypes.Kanot]: 'Kanot',
  [SportTypes.Cykelbana]: 'Cykelbana',
  [SportTypes.Volleyboll]: 'Volleyboll',
  [SportTypes.Triathlon]: 'Triathlon',
  [SportTypes.Segling]: 'Segling',
  [SportTypes.Skicross]: 'Skicross',
  [SportTypes.UndefinedSport93]: 'Ospecificerad',
  [SportTypes.UndefinedSport94]: 'Ospecificerad',
  [SportTypes.UndefinedSport95]: 'Ospecificerad',
  [SportTypes.UndefinedSport96]: 'Ospecificerad',
  [SportTypes.Shorttrack]: 'Shorttrack',
  [SportTypes.Skeleton]: 'Skeleton',
  [SportTypes.UndefinedSport99]: 'Ospecificerad',
  [SportTypes.UndefinedSport100]: 'Ospecificerad',
  [SportTypes.UndefinedSport101]: 'Ospecificerad',
  [SportTypes.UndefinedSport102]: 'Ospecificerad'
};
const toExport = {
  SportTypes,
  SportTypeToString
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.sportTypes', toExport);
}

 })(window);